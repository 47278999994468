import { HTTPClient } from "../http";

/**
 * The Account type contains all fields pertaining to a user's account.
 */
export type Account = {
  /**
   * The account's unique identifier.
   */
  id: string;
  /**
   * The user's email address.
   */
  email: string;
  /**
   * When the account was created.
   */
  createdAt: Date;

  /**
   * The kind of account.
   */
  type: AccountType;

  /**
   * Whether the account has verified its email address.
   */
  emailVerified: boolean;
};

/**
 * The AccountType enum is used to classify different account types.
 */
export enum AccountType {
  Unspecified = "",
  Client = "client",
  Coach = "coach",
}

/**
 * The CreateRequest type represents the HTTP request for account creation.
 */
type CreateRequest = {
  /**
   * The account's email address.
   */
  email: string;
  /**
   * The account's password.
   */
  password: string;

  /**
   * The type of account to create.
   */
  type: AccountType;
};

/**
 * The CreateResponse type represents the HTTP response for account creation.
 */
type CreateResponse = {
  /**
   * The account's unique identifier.
   */
  id: string;
};

/**
 * The GetResponse type represents the HTTP response for querying an account.
 */
type GetResponse = {
  /**
   * The caller's account.
   */
  account: Account;
};

/**
 * The AccountClient class is used to manage Account data against the API.
 */
export class AccountClient extends HTTPClient {
  /**
   * Create a new account.
   * @param email The account's email address
   * @param password The account's password
   * @param type The type of account to create
   * @returns The account's unique identifier
   */
  async create(
    email: string,
    password: string,
    type: AccountType,
  ): Promise<string> {
    const { id } = await this.post<CreateRequest, CreateResponse>(
      "/api/account",
      { email, password, type },
    );
    return id;
  }

  /**
   * Delete the caller's account.
   */
  async remove(): Promise<void> {
    await this.delete("/api/account");
  }

  /**
   * Get account details for the given identifier.
   * @param accountId The account identifier.
   * @returns The caller's account details
   */
  async find(accountId: string): Promise<Account> {
    const { account } = await this.get<GetResponse>(
      `/api/account/${accountId}`,
    );

    return account;
  }

  /**
   * Update an account's password.
   * @param accountId The account to update
   * @param password The account's current password
   * @param newPassword The account's new password
   */
  async updatePassword(
    accountId: string,
    password: string,
    newPassword: string,
  ): Promise<void> {
    await this.put(`/api/account/${accountId}/password`, {
      password,
      newPassword,
    });
  }
}
