import { HTTPClient } from "@/lib/http";

/**
 * The Setup type describes which parts of the setup process an account
 * has gone through.
 */
export type Setup = {
  /**
   * The account has verified their email.
   */
  emailVerified: boolean;
  /**
   * The account has created their profile.
   */
  profileCreated: boolean;
  /**
   * The account has linked their coach. (clients only)
   */
  coachLinked: boolean;
  /**
   * The account has created a subscription. (coaches only)
   */
  subscriptionCreated: boolean;
  /**
   * The account has completed the set up process.
   */
  complete: boolean;
};

/**
 * The FindResponse type is the response DTO given when querying an account's
 * setup process.
 */
type FindResponse = {
  /**
   * The setup details.
   */
  setup: Setup;
};

/**
 * The SetupClient class is used to perform API requests regarding account set up.
 */
export class SetupClient extends HTTPClient {
  /**
   * Get the setup details for a given account.
   * @param accountId The account to query details for.
   */
  async find(accountId: string): Promise<Setup> {
    const { setup } = await this.get<FindResponse>(
      `/api/account/${accountId}/setup`,
    );

    return setup;
  }

  /**
   * Complete the setup process for an account.
   * @param accountId The account to complete setup for.
   */
  async complete(accountId: string): Promise<void> {
    await this.post(`/api/account/${accountId}/setup`, accountId);
  }
}
