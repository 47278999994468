import "chartjs-adapter-date-fns";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  LineElement,
  Colors,
  Filler,
  BarElement,
  Title,
  Tooltip,
);
