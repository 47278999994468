import { Workout } from "@/lib/workout";
import { Account } from "@/lib/account";
import { Profile } from "@/lib/profile";
import { ConfigProviderTheme } from "vant";
import { TrainingPlan } from "@/lib/training";
import { defineStore } from "pinia";

export interface State {
  workout?: Workout;
  account?: Account;
  client?: Profile;
  theme?: ConfigProviderTheme;
  trainingPlan?: TrainingPlan;
  flags?: Record<string, boolean>;
  editWorkout?: Workout;
  editTrainingPlan?: TrainingPlan;
  lastFeedCheck?: Date;
}

export const useStore = defineStore("store", {
  persist: true,
  state: (): State => ({
    workout: undefined,
    account: undefined,
    client: undefined,
    theme: undefined,
    trainingPlan: undefined,
    flags: undefined,
    editTrainingPlan: undefined,
    editWorkout: undefined,
    lastFeedCheck: undefined,
  }),
  actions: {
    setAccount(account: Account) {
      this.account = account;
    },
    setWorkout(workout?: Workout) {
      this.workout = workout;
    },
    setClient(profile?: Profile) {
      this.client = profile;
    },
    setTheme(theme: ConfigProviderTheme) {
      this.theme = theme;
    },
    setTrainingPlan(trainingPlan: TrainingPlan) {
      this.trainingPlan = trainingPlan;
    },
    setFlags(flags: Record<string, boolean>) {
      this.flags = flags;
    },
    setEditWorkout(workout?: Workout) {
      this.editWorkout = workout;
    },
    setEditTrainingPlan(plan?: TrainingPlan) {
      this.editTrainingPlan = plan;
    },
    setLastFeedCheck(lastFeedCheck?: Date) {
      this.lastFeedCheck = lastFeedCheck;
    },
    clear() {
      this.$reset();
    },
  },
});
