/**
 * The HTTPError type represents an error as returned via the HTTP API.
 */
export type HTTPError = {
  /**
   * The error message.
   */
  readonly message: string;
  /**
   * The HTTP status code.
   */
  readonly code: HTTPStatus;
};

/**
 * The HTTPStatus enumeration contains regularly checked HTTP status
 * codes.
 */
export enum HTTPStatus {
  Ok = 200,
  Unauthorized = 401,
  NotFound = 404,
  Forbidden = 403,
  InternalServerError = 500,
  BadRequest = 400,
  PreconditionFailed = 412,
}

/**
 * The HTTPClient class is used to perform HTTP requests against the API.
 */
export class HTTPClient {
  /**
   * Perform an HTTP GET request.
   * @param uri The HTTP request URI
   * @returns The JSON-decoded response.
   */
  protected async get<Response>(uri: string): Promise<Response> {
    return await this.call("GET", uri);
  }

  /**
   * Perform an HTTP DELETE request.
   * @param uri The HTTP request URI
   * @returns The JSON-decoded response.
   */
  protected async delete<Response>(uri: string): Promise<Response> {
    return await this.call("DELETE", uri);
  }

  /**
   * Perform an HTTP POST request.
   * @param uri The HTTP request URI
   * @param body The HTTP request body
   * @returns The JSON-decoded response
   */
  protected async post<Request, Response>(
    uri: string,
    body: Request,
  ): Promise<Response> {
    return await this.call("POST", uri, body);
  }

  /**
   * Perform an HTTP PUT request.
   * @param uri The HTTP request URI
   * @param body The HTTP request body
   * @returns The JSON-decoded response
   */
  protected async put<Request, Response>(
    uri: string,
    body: Request,
  ): Promise<Response> {
    return await this.call("PUT", uri, body);
  }

  /**
   * Perform an HTTP POST request using a multipart form.
   * @param uri The HTTP request URI
   * @param body The multipart form data
   * @returns The JSON-decoded response
   */
  protected async form<Response>(
    uri: string,
    body: FormData,
  ): Promise<Response> {
    const headers: Record<string, string> = {
      Accepts: "application/json",
    };

    const result = await fetch(uri, {
      method: "POST",
      headers,
      body: body,
    });

    if (result.ok) {
      return (await result.json()) as Response;
    }

    throw (await result.json()) as HTTPError;
  }

  /**
   * Perform an HTTP request as described by the request parameters, including the bearer
   * token if set.
   * @param method The HTTP request method
   * @param uri The HTTP request URI
   * @param body The HTTP request body
   * @returns The JSON-decoded response
   */
  private async call<Request, Response>(
    method: string,
    uri: string,
    body?: Request,
  ): Promise<Response> {
    const headers: Record<string, string> = {
      Accepts: "application/json",
    };

    if (body) {
      headers["Content-Type"] = "application/json";
    }

    const result = await fetch(uri, {
      method,
      headers,
      body: body
        ? JSON.stringify(body, (k, v) => {
            if (v === "") {
              return v;
            }

            if (v === true || v === false) {
              return v;
            }

            if (isNaN(v)) {
              return v;
            }

            return Number(v);
          })
        : undefined,
    });

    if (result.ok) {
      return (await result.json()) as Response;
    }

    throw (await result.json()) as HTTPError;
  }
}
