import flagsmith from "flagsmith";
import { useStore } from "@/store";

export function setupFlagsmith() {
  flagsmith.init({
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_KEY ?? "",
    onChange() {
      const store = useStore();
      const flags = ["checkin", "fluids"];

      const state: Record<string, boolean> = {};

      for (const flag of flags) {
        state[flag] = flagsmith.hasFeature(flag);
      }

      store.setFlags(state);
    },
  });
}
