import { showConfirmDialog, showNotify } from "vant";

const duration = 5000;

/**
 * Display an error notification.
 * @param message The error message to display
 */
export function error(message: string): void {
  showNotify({ type: "danger", message, duration, position: "bottom" });
}

/**
 * Display a warning notification.
 * @param message The warning message to display
 */
export function warning(message: string): void {
  showNotify({ type: "warning", message, duration, position: "bottom" });
}

/**
 * Display an informational message.
 * @param message The information to display
 */
export function info(message: string): void {
  showNotify({ type: "primary", message, duration, position: "bottom" });
}

/**
 * Display a confirmation window.
 * @param title The title of the confirm window
 * @param message The message of the confirm window
 * @returns A promise resolving to true or false depending on the user's selection.
 */
export async function confirm(
  title: string,
  message: string,
): Promise<boolean> {
  try {
    await showConfirmDialog({ title, message });
    return true;
  } catch (e) {
    return false;
  }
}
