import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Lazyload, Locale } from "vant";
import enUS from "vant/es/locale/lang/en-US";
import "./chart";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { useStore } from "@/store";
import { setupSentry } from "@/sentry";
import { setupFlagsmith } from "@/flagsmith";
import "vant/lib/index.css";
import "vant/es/dialog/style";
import "vant/es/notify/style";
import "vant/es/image-preview/style";
import "@vant/touch-emulator";

Locale.use("en-US", enUS);
const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app
  .use(pinia)
  .use(router)
  .use(Lazyload)
  .use((app) => {
    app.config.globalProperties.store = useStore();
  })
  .mount("#app");

setupSentry(app);
setupFlagsmith();
