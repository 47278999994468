import * as Sentry from "@sentry/vue";
import { Vue } from "@sentry/vue";
import info from "../package.json";
import router from "@/router";

export function setupSentry(app: Vue) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.NODE_ENV,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    release: info.version,
    trackComponents: true,
    transport: Sentry.makeFetchTransport,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.sessionTimingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
