import { HTTPClient, HTTPError, HTTPStatus } from "../http";

/**
 * The LoginResponse type represents the HTTP response for account authentication.
 */
type LoginResponse = {
  /**
   * The bearer token to use for subsequent requests.
   */
  token: string;
  /**
   * The authenticated account's unique identifier.
   */
  accountId: string;
};

/**
 * The LoginRequest type represents the HTTP request for account authentication.
 */
type LoginRequest = {
  /**
   * The account's email address.
   */
  email: string;
  /**
   * The account's password.
   */
  password: string;
};

/**
 * The AuthClient type is used for performing authentication of account's against the HTTP API.
 */
export class AuthClient extends HTTPClient {
  /**
   * Authenticate using the given credentials.
   * @param email The account's email address
   * @param password The account's password
   * @returns The bearer token and account identifier
   */
  async login(email: string, password: string): Promise<LoginResponse> {
    return await this.post<LoginRequest, LoginResponse>("/api/auth", {
      email,
      password,
    });
  }

  /**
   * Check the current token is still valid.
   * @returns A promise, resolving true if the token is still valid
   */
  async authenticated(): Promise<boolean> {
    try {
      await this.get("/api/auth");
      return true;
    } catch (e) {
      const err = e as HTTPError;
      switch (err.code) {
        // These 3 status codes indicate an expired token, a deleted account or an invalid token.
        case HTTPStatus.Unauthorized:
          return false;
        case HTTPStatus.PreconditionFailed:
          return false;
        case HTTPStatus.BadRequest:
          return false;
        default:
          throw e;
      }
    }
  }

  /**
   * Start the email verification process for an account.
   * @param accountId The account to trigger a verification email for.
   */
  async startEmailVerification(accountId: string): Promise<void> {
    await this.post(`/api/account/${accountId}/verify/email`, {});
  }

  /**
   * Verify an account's email address.
   * @param accountId The account whose email we're verifying.
   * @param code The email verification code.
   */
  async verifyEmail(accountId: string, code: string): Promise<void> {
    await this.put(`/api/account/${accountId}/verify/email`, { code });
  }

  /**
   * End the caller's current session.
   */
  async logout(): Promise<void> {
    await this.delete(`/api/auth`);
  }
}
