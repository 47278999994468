<template>
  <van-config-provider :theme="theme">
    <router-view />
  </van-config-provider>
</template>

<script lang="ts">
import "./main.css";
import { defineComponent } from "vue";
import { ConfigProviderTheme } from "vant";
import { registerSW } from "virtual:pwa-register";
import { confirm } from "@/lib/alert";

export default defineComponent({
  name: "App",

  computed: {
    theme(): ConfigProviderTheme {
      if (this.store.theme) {
        return this.store.theme;
      }

      let theme: ConfigProviderTheme = "light";
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        theme = "dark";
      }

      this.store.setTheme(theme);
      return theme;
    },
  },

  async mounted() {
    const updateSW = registerSW({
      async onNeedRefresh() {
        const confirmation = await confirm(
          "Update Available",
          "Do you want to update?",
        );
        if (!confirmation) {
          return;
        }

        await updateSW();
      },
    });
  },
});
</script>
